import { Flex, chakra, Skeleton, Link, Image } from '@chakra-ui/react';
import { SliseAd } from '@slise/embed-react';
import React, { useEffect } from 'react';

import type { BannerProps } from './types';

import config from 'configs/app';

type AdData = {
  content_ads: string;
  image_link: string;
  title: string;
  url_ads: string;
}

const SliseBanner = ({ className, platform }: BannerProps) => {
  const [ adData, setAdData ] = React.useState<AdData | null>(null);
  const [ isLoading, setIsLoading ] = React.useState(true);
  const baseUrl = config.getEnvValue('NEXT_PUBLIC_APP_HOST');
  

  useEffect(() => {
    // if (isBrowser()) {
    fetch(`https://${baseUrl}/api/v2/get_ads?type=all`)
      .then(res => res.status === 200 ? res.json() : null)
      .then((_data) => {
        const data = _data as AdData;
        setAdData(data);
      })
      .finally(() => {
        // setAdData(MOCK);
        setIsLoading(false);
      });
    //}
  }, [ ]);

  if (isLoading) {
    return (
      <Skeleton
        className={ className }
        h={{ base: 12, lg: 6 }}
        w="100%"
        flexGrow={ 1 }
        maxW="800px"
        display="block"
      />
    );
  }

  if (!adData) {
    return null;
  }

  if (platform === 'desktop') {
    return (
      <Flex className={ className } h="90px">
        {/* <SliseAd
          slotId={ config.chain.name || '' }
          pub="pub-10"
          format="728x90"
          style={{ width: '728px', height: '90px' }}/> */}
          <Link href={ adData.url_ads } target="_blank"><Image src={ adData.image_link } mb="-4px" mr={ 1 } display="inline" alt="" style={{ width: '728px', height: '90px' }}/></Link>
      </Flex>
    );
  }

  if (platform === 'mobile') {
    return (
      <Flex className={ className } h="90px">
        {/* <SliseAd
          slotId={ config.chain.name || '' }
          pub="pub-10"
          format="270x90"
          style={{ width: '270px', height: '90px' }}/> */}
          <Link href={ adData.url_ads } target="_blank"><Image src={ adData.image_link } mb="-4px" mr={ 1 } display="inline" style={{ width: '270px', height: '90px' }} alt=""/></Link>
      </Flex>
    );
  }

  return (
    <>
      <Flex className={ className } h="90px" display={{ base: 'none', lg: 'flex' }}>
        <Link href={ adData.url_ads } target="_blank"><Image src={ adData.image_link } mb="-4px" mr={ 1 } display="inline" alt="" style={{ width: '728px', height: '90px' }}/></Link>
        {/* <SliseAd
          slotId={ config.chain.name || '' }
          pub="pub-10"
          format="728x90"
          style={{ width: '728px', height: '90px' }}/> */}
      </Flex>
      <Flex className={ className } h="90px" display={{ base: 'flex', lg: 'none' }}>
        {/* <SliseAd
          slotId={ config.chain.name || '' }
          pub="pub-10"
          format="270x90"
          style={{ width: '270px', height: '90px' }}/> */}
          <Link href={ adData.url_ads } target="_blank"><Image src={ adData.image_link } mb="-4px" mr={ 1 } display="inline" style={{ width: '270px', height: '90px' }} alt=""/></Link>
      </Flex>
    </>
  );
};

export default chakra(SliseBanner);
